<template>
  <span v-if="showversion()">
    <a
      :href="sanitizeUrl(commitUrl)"
      rel="noopener"
      target="_blank"
    >{{
      env.VERCEL_GIT_COMMIT_MESSAGE
    }}</a>
    -
  </span>
</template>
<script>
import { mapStores } from "pinia";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { useActionStore } from "~/stores/action";
import { useRuntimeConfig } from "#app";
import { useUserStore } from "~/stores/user";

const {
  public: { git },
} = useRuntimeConfig();

export default {
  name: "VersionLink",
  components: {},
  props: {},
  data() {
    return {
      env: {
        VERCEL_GIT_COMMIT_MESSAGE: git.message,
        VERCEL_GIT_COMMIT_SHA: git.sha,
      },
    };
  },
  computed: {
    ...mapStores(useActionStore, useUserStore),
    commitUrl() {
      return (
        "https://github.com/Toolsquare-io/frontend/commit/" +
        this.env.VERCEL_GIT_COMMIT_SHA
      );
    },
  },
  methods: {
    sanitizeUrl,
    showversion() {
      return (
        this.env.VERCEL_GIT_COMMIT_MESSAGE &&
        this.env.VERCEL_GIT_COMMIT_SHA &&
        this.userStore.is_superuser()
      );
    },
  },
};
</script>
