<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright pull-right">
        v{{ version }} -
        <version-link />
        <button
          class="btn btn-link"
          onclick="window.triggerModal()"
        >
          change cookie preferences
        </button>
        -
        <span>
          <a
            href="https://www.toolsquare.io/legal/platform-privacy-policy"
            rel="noopener"
            target="_blank"
          >Privacy Policy</a>
          -
        </span>
        &copy;
        <span>{{ new Date().getFullYear() }}
          <a
            href="https://www.toolsquare.io"
            rel="noopener"
            target="_blank"
          >Toolsquare.io</a></span>
      </div>
    </div>
  </footer>
</template>
<script setup>
import VersionLink from "~/components/general/VersionLink.vue";
import { useRuntimeConfig } from "#app";

const config = useRuntimeConfig();
const version = config.public.VERSION;
</script>
<script>
export default {
  name: "PrivateFooter",
};
</script>
